import * as React from "react"
import { Formik } from "formik"
const axios = require("axios").default

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const [msg, setMsg] = React.useState("")
  const [isSent, setIsSent] = React.useState(false)

  return (
    <Layout
      className="text-center"
      style={{ backgroundColor: "#eee4da", overflowY: "scroll" }}
    >
      <Seo title="Contact Us" />
      <div
        className="max-w-xl mx-auto px-4 pb-24 tracking-wider pt-64 md:pt-24"
        style={{ color: "#958e74" }}
      >
        <h1 className="text-3xl md:text-5xl font-semibold text-primary">
          Contact Us
        </h1>
        <div className="py-8 text-base md:text-xl font-semibold text-primary">
          <p>6203 0080</p>
          <p>sales@kajima.com.sg</p>
          <p className="pt-4 text-sm md:text-lg text-primary">
            80 Marine Perade Road,
            <br />
            #19-05, Singapore 449269
          </p>
        </div>
        <Formik
          initialValues={{ telephone: "", message: "", name: "", email: "" }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = "Required"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            axios
              .post(
                "/api/contact.php",
                {
                  ...values,
                },
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then(response => {
                setSubmitting(false)
                resetForm()
                setIsSent(true)
              })
              .catch(error => {
                setSubmitting(false)
                alert(error)
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              className="space-y-4 flex flex-col items-center"
            >
              <input
                name="name"
                className="p-2 text-primary placeholder:text-primary/50 block w-full md:w-4/6 text-center text-sm"
                placeholder="- Your Name -"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required={true}
              />
              {errors.name && touched.name && (
                <span className="text-red-500 text-sm">{errors.name}</span>
              )}
              <input
                name="email"
                className="p-2 text-primary placeholder:text-primary/50 block w-full md:w-4/6 text-center text-sm"
                placeholder="- Your Email -"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                required={true}
              />
              {errors.email && touched.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
              <input
                name="telephone"
                className="p-2 text-primary placeholder:text-primary/50 block w-full md:w-4/6 text-center text-sm"
                placeholder="- Your Telephone -"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telephone}
                required={true}
              />
              {errors.telephone && touched.telephone && (
                <span className="text-red-500 text-sm">{errors.telephone}</span>
              )}
              <textarea
                name="message"
                className="p-2 text-primary placeholder:text-primary/50 block w-full text-center text-sm"
                placeholder="- Your Message -"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                required={true}
                rows={5}
              ></textarea>
              {errors.message && touched.message && (
                <span className="text-red-500 text-sm">{errors.message}</span>
              )}
              <button
                type="submit"
                disabled={isSubmitting || isSent}
                style={{ backgroundColor: "#968f75" }}
                className="px-8 py-2 text-sm text-black font-semibold bg-cover bg-[url('/images/bg.jpg')] hover:bg-none uppercase disabled:text-white/30 disabled:bg-primary/50"
              >
                {isSent ? "Submitted" : "Submit"}
              </button>
              {isSent && (
                <p className="text-sm text-primary">
                  Message submitted succesfully. We will be in touch with you
                  soon.
                </p>
              )}
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  )
}

export default IndexPage
